import { mcn } from "@/utils/mergeClassNames";
export const LearningOpportunityCardContainerShimmer = () => {
  const cardCount = 8;
  return (
    <div className="overflow-hidden">
      <div className={mcn("card-container flex gap-default py-sm")}>
        {Array.from(Array(cardCount)).map((_, index) => (
          <div
            key={index}
            className={mcn(
              "bg-white/20 rounded-2",
              "h-[405px]", // Same height as LO card. 
              "animate-pulse",
              "flex-shrink-0",
              "card-1 tablet-sm:card-2 tablet:card-3 desktop:card-4 "
            )}
          />
        ))}
      </div>
      {/* empty div height same space as pagination */}
      <div className="h-3xl"> </div>
    </div>
  );
};
