import { ScreenKey, useCurrentScreenSize } from "@/utils";

// screens here must match breakpoints for card classnames in <LearningOpportunityCardContainer />
type ScreenKeyArray = (ScreenKey | undefined)[];
const screen1: ScreenKeyArray = [undefined];
const screen2: ScreenKeyArray = ["tablet-sm"];
const screen3: ScreenKeyArray = ["tablet", "tablet-lg", "laptop", "laptop-lg"];
const screen4: ScreenKeyArray = ["desktop"];

export function useCardCountFromScreenSize() {
  const current = useCurrentScreenSize();
  if (screen1.includes(current)) return 1;
  if (screen2.includes(current)) return 2;
  if (screen3.includes(current)) return 3;
  if (screen4.includes(current)) return 4;
  return 1;
}
