import { colors } from "../tailwind/zeplinData";

export type Color =
  | "blue"
  | "ultra-light-blue"
  | "light-blue"
  | "light-gray"
  | "light-yellow"
  | "yellow";

export const getColorCode = (color?: Color) => {
  switch (color) {
    case "blue":
      return colors["blue"];
    case "ultra-light-blue":
      return colors["ultra-light-blue"];
    case "light-blue":
      return colors["light-blue"];
    case "light-gray":
      return colors["grey-160"];
    case "light-yellow":
      return colors["light-yellow"];
    case "yellow":
      return colors["yellow-100"];
    default:
      return color;
  }
};
