import Button from "@/components/Button";
import { mcn } from "@/utils/mergeClassNames";
import { CarouselHeightClassNames } from "./CarouselSection";

type Props = {
  title: string;
  text: string;
  actionText: string;
  actionHref: string;
};

export const CarouselTextContent = (props: Props) => {
  const titleFontClassnames = mcn(
    "text-style-headline3 text-[30px]",
    "tablet:text-[50px] tablet:leading-[68px]"
  );
  return (
    <div
      className={mcn(
        "container flex items-center",
        "relative z-30",
        CarouselHeightClassNames
      )}
    >
      <div
        className={mcn(
          "grid grid-cols-12 gap-x-default",
          "laptop:-ml-[180px] laptop:mr-[180px]",
          "desktop:ml-0 desktop:mr-0"
        )}
      >
        <div
          className={mcn(
            "col-start-1 col-span-12",
            "laptop:col-span-9",
            "desktop:col-start-1 desktop:col-span-7",
            "mt-[180px]",
            "tablet-sm:mt-[350px]",
            "laptop:mt-0",
            "mb-sm"
          )}
        >
          <h1
            className={mcn(
              "text-red-100 bg-blue/90",
              "box-decoration-clone px-xs rounded-2",
              titleFontClassnames
            )}
          >
            {props.title}
          </h1>
        </div>

        <div
          className={mcn(
            "col-start-1 col-span-full",
            "tablet:col-span-9",
            "desktop:col-span-7"
          )}
        >
          <span
            className={mcn(
              "text-style-body2 text-light-yellow bg-blue/90",
              "box-decoration-clone px-2xs",
              "tablet-sm:text-style-headline4"
            )}
          >
            {props.text}
          </span>
        </div>

        <div className="col-start-1 col-span-full pt-lg">
          <Button label={props.actionText} href={props.actionHref} />
        </div>
      </div>
    </div>
  );
};
