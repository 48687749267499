import Link from "next/link";

import { LearningOpportunityListItem } from "../../server/backend-api";
import { IconActionCalendar } from "@/components/Icons";
import { EciuImage } from "../EciuImage";
import {
  getStatusLabelPropsFromStatus,
  StatusLabel,
} from "../StatusLabel/StatusLabel";
import { mcn } from "@/utils/mergeClassNames";
import { LearningOpportunityType } from "@/server/backend-api/generated";

type Props = {
  item: LearningOpportunityListItem;
};

export const LearningOpportunityCard = ({ item }: Props) => {
  const isModule = item.type === LearningOpportunityType.MicroModule;
  const statusProps = getStatusLabelPropsFromStatus(
    item.status,
    item.userStatus
  );

  return (
    // py-sm to make sure shadow and hover zoom/scale effect isn't clipped
    <div className="py-sm" data-testid="learning-opp-card">
      <Link
        href={item.url}
        className={mcn(
          "group block outline-none",
          "transition-transform ease-out",
          "hover:scale-[1.02] focus-visible:scale-[1.02]"
        )}
      >
        <div
          className={mcn(
            "relative bg-white rounded-2 card-shadow",
            "group-focus-visible:default-focus",
            "group-active:card-overlay",
            isModule && "mt-30" // mt-30 as card starts half way down on image (image moved up with -mt-30)
          )}
        >
          <div className={mcn("flex justify-center")}>
            <div
              className={mcn(
                "h-60 z-10 relative",
                !isModule && "w-full",
                isModule && "max-w-full aspect-square rounded-full",
                isModule && "-mt-30", // -mt-30 to move image half way up above card
                isModule && "clip-path-circle", // clip circle to round off egde of StatusLabel
                /**
                 * Add extra active overlay on image wrapper
                 * But use clip-path so that only top half (part outside main card)
                 * is covered by the extra overlay to avoid double overlay
                 */
                isModule && "group-active:card-overlay",
                isModule && "group-active:before:clip-path-top-half"
              )}
            >
              {statusProps !== null && (
                <StatusLabel
                  text={statusProps.text}
                  color={statusProps.color}
                  applicationEndDate={item.applicationPeriodEndDate}
                  includeBanner={true}
                  bannerSizeClassName={isModule ? "w-5" : undefined}
                  className={mcn(
                    "absolute z-20 top-10",
                    isModule && "pl-3" // Move text into circle
                  )}
                />
              )}
              <EciuImage
                rounded={isModule ? "full" : "top-2"}
                className="relative"
                src={item.imageUri}
              />
            </div>
          </div>
          <div className="text-text-primary">
            <div className="p-md space-y-2">
              <div
                className={mcn(
                  "text-style-subtitle1 h-[72px] line-clamp-3",
                  "group-hover:underline",
                  "group-focus-visible:underline"
                )}
              >
                {item.title}
              </div>
            </div>
            <div
              className={mcn(
                "flex justify-between",
                "text-text-secondary text-style-caption",
                "p-xs",
                "border-t border-grey-20 border-dashed h-[53px]"
              )}
            >
              {item.studyPeriodShort && (
                <div>
                  <span className="text-style-overline uppercase">
                    Study period
                  </span>
                  <div className="flex items-center">
                    <IconActionCalendar className="text-base mr-1 mb-1" />
                    <span>{item.studyPeriodShort}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
