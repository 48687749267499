import { mcn } from "@/utils/mergeClassNames";
import { IconActionChevronDown } from "./Icons";

type Props = {
  targetSectionId: string;
};

interface GoToSectionProps {
  targetSectionId: string;
  ariaLabel: string;
  className?: string;
  padding?: string;
  textColor?: "text-yellow-100" | "text-white";
  textSize?: string;
}

export function GoToSectionButton(props: GoToSectionProps) {
  return (
    <button
      aria-label={props.ariaLabel}
      onClick={() => {
        const destination = document.querySelector(`#${props.targetSectionId}`);
        if (destination) {
          destination.scrollIntoView({ behavior: "smooth" });
          return;
        }
      }}
      className={mcn(
        props.textColor ?? "text-yellow-100",
        "rounded-3",
        "flex flex-col justify-center items-center",
        props.padding ?? "p-xs",
        "animate-fade-down",
        props.className
      )}
    >
      <IconActionChevronDown className={props.textSize ?? "text-[40px]"} />
    </button>
  );
}

export const LearnMoreSectionButton = (props: Props) => {
  return (
    <div
      className={mcn("absolute z-40 bottom-lg", "flex w-full justify-center")}
    >
      <GoToSectionButton
        targetSectionId={props.targetSectionId}
        ariaLabel={"Go to next section"}
      />
    </div>
  );
};
