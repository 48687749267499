import { IconNavigationChevronRight } from "@/components/Icons";
import { mcn } from "@/utils/mergeClassNames";

type Props = {
  disabled: boolean;
  onClick: () => void;
  ariaLabel: string;
  direction: "left" | "right";
  className?: string;
};
export const CardContainerChevronButton = (props: Props) => (
  <button
    className={mcn(
      "text-light-blue rounded-full p-xs",
      "hover:text-white hover:bg-light-blue ",
      "disabled:text-light-blue/30",
      "disabled:hover:bg-[transparent]",
      props.className
    )}
    disabled={props.disabled}
    onClick={props.onClick}
    aria-label={props.ariaLabel}
  >
    <IconNavigationChevronRight
      className={mcn("text-[24px]", props.direction === "left" && "rotate-180")}
    />
  </button>
);
