import { mcn } from "@/utils/mergeClassNames";

type Args = {
  fillColor?: string;
  lineColor?: string;
  backgroundColor?: string;
  opacity?: number;
  className?: string;
  sizeClassName?: string;
  width?: string;
  height?: string;
};

const getEciuPolygonSvg = (args: Args) => {
  const background = args.backgroundColor ?? "transparent";
  const foregroundColor = args.fillColor ?? "transparent";
  const lineColor =
    args.lineColor ??
    (background === foregroundColor ? "currentColor" : foregroundColor);

  const opacity = args?.opacity;
  const svg = `<svg 
      viewBox="0 0 865 866"
      opacity="${opacity}"
      xmlns="http://www.w3.org/2000/svg"
      width="${args.width}"
      height="${args.height}"
    >
      <path 
        d="M865 0 H0 V465.396z" stroke-width="0" fill="${background}" 
      />
      <path 
        d="M0 866 H866 V400.35z" stroke-width="0" fill="${background}"  
      />
      <path 
        d="M861 6.678 V400.35 L4 859.068 V465.396 L861 6.678z" 
        stroke-width="8" 
        stroke="${lineColor}"  
        fill="${foregroundColor}" 
      />
  </svg>
  `;
  return svg;
};

export const getEciuPolygonAsSrc = (args: Args) => {
  const svg = getEciuPolygonSvg(args);
  return `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;
};

export const EciuPolygonIcon = (props: Args) => {
  const svg = getEciuPolygonSvg(props);
  return (
    <div
      className={mcn(props.sizeClassName ?? "w-lg", props.className)}
      dangerouslySetInnerHTML={{
        __html: svg,
      }}
    />
  );
};
