import { mcn } from "@/utils/mergeClassNames";
import { RefObject } from "react";
import { CardContainerChevronButton } from "./CardContainerChevronButton";

type Props = {
  containerRef: RefObject<HTMLDivElement>;
  cardRefs: RefObject<(HTMLDivElement | null)[]>;

  currentPosition: number;
  visibleCardCount: number;
  cardCount: number;
};

const scrollToCard = (
  containerRef: RefObject<HTMLDivElement>,
  cardRefs: RefObject<(HTMLDivElement | null)[]>,
  visibleCount: number,
  scrollPosition: number | undefined
) => {
  const containerElement = containerRef.current;
  const cardElements = cardRefs.current;
  if (
    !containerElement ||
    !cardElements ||
    !cardElements[0] ||
    scrollPosition === undefined
  ) {
    return;
  }

  const firstCardOffsetLeft = cardElements[0].offsetLeft;

  const targetIndex = Math.min(
    scrollPosition * visibleCount,
    cardElements.length - 1
  );
  const targetCardElem = cardElements[targetIndex];

  if (targetCardElem) {
    containerElement.scroll({
      left: targetCardElem.offsetLeft - firstCardOffsetLeft,
      top: 0,
      behavior: "smooth",
    });
  }
};

export const CardContainerPagination = ({
  containerRef,
  cardRefs,
  cardCount,
  visibleCardCount,
  currentPosition,
}: Props) => {
  const numberOfPositions = visibleCardCount
    ? Math.ceil(cardCount / visibleCardCount)
    : 0;
  const moveTo = (position: number) => {
    scrollToCard(containerRef, cardRefs, visibleCardCount, position);
  };
  const moveToNext = () => moveTo(currentPosition + 1);
  const moveToPrev = () => moveTo(currentPosition - 1);
  const hasPrev = currentPosition > 0;
  const hasNext = currentPosition < numberOfPositions - 1;

  return numberOfPositions <= 1 ? null : (
    <div className="pt-md space-x-2 flex items-center justify-center">
      <CardContainerChevronButton
        className="max-tablet-lg:touch-screen:hidden"
        onClick={() => moveToPrev()}
        ariaLabel="Previous"
        disabled={!hasPrev}
        direction="left"
      />

      {Array.from(Array(numberOfPositions).keys()).map((_, position) => (
        <button
          aria-label="Show more learning opportunities"
          key={position}
          onClick={() => moveTo(position)}
          className={mcn(
            "w-4 h-4 rounded-full",
            position === currentPosition ? "bg-light-blue" : "bg-light-blue/40",
            "hover:bg-blue"
          )}
        />
      ))}

      <CardContainerChevronButton
        className="max-tablet-lg:touch-screen:hidden"
        onClick={() => moveToNext()}
        ariaLabel="Next"
        disabled={!hasNext}
        direction="right"
      />
    </div>
  );
};
