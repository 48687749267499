import { useRouter } from "next/router";
import { RefObject, useEffect } from "react";
import { Assert } from "./assert";

type Instance = {
  minFontSize: number;
  maxFontSize: number;

  element: HTMLElement;

  previousParentClientWidth?: number;
  previousFontSize?: number;

  animationFrameId?: number | undefined;
  timeoutId?: number | undefined;
};

const requestRedraw = (instance: Instance) => {
  if (instance.animationFrameId) {
    window.cancelAnimationFrame(instance.animationFrameId);
  }

  instance.animationFrameId = window.requestAnimationFrame(() => {
    Assert.check(instance.element.parentElement != undefined);
    const parentClientWidth = instance.element.parentElement.clientWidth;
    const shouldRedraw =
      parentClientWidth !== instance.previousParentClientWidth;

    if (shouldRedraw) {
      // previousFontSize is needed in the algoritm, but will be empty on first redraw
      if (!instance.previousFontSize) {
        const currentStyle = window.getComputedStyle(instance.element, null);
        instance.previousFontSize = parseFloat(
          currentStyle.getPropertyValue("font-size")
        );
      }

      // Let's calculate the new font size
      const fontSize = Math.min(
        Math.max(
          instance.minFontSize,
          (parentClientWidth / instance.element.scrollWidth) *
            instance.previousFontSize
        ),
        instance.maxFontSize
      );

      // Only wrap when at minimum font size
      const whiteSpace =
        fontSize === instance.minFontSize ? "normal" : "nowrap";

      // Apply style
      instance.element.style.whiteSpace = whiteSpace;
      instance.element.style.fontSize = `${fontSize}px`;

      // Update instance
      instance.previousFontSize = fontSize;
      instance.previousParentClientWidth = parentClientWidth;
    }
  });
};

/**
 * Utility that scales down font-size of text to
 * fit it on one line of its container.
 *
 * Font-size will stay between provided max and
 * min value, which means long texts will stil
 * wrap on mulitple lines.
 *
 * This code is based on fitty.js (https://github.com/rikschennink/fitty),
 * but has been simplifed and changed to work with
 * Typescript and React (now exposed as a react hook
 * useFitTextToContainer)
 *
 * Provided text-conatiner should have following classes:
 * inline-block
 * whitespace-nowrap
 *
 */
export const useFitTextToContainer = (
  ref: RefObject<HTMLElement>,
  minFontSize: number,
  maxFontSize: number
) => {
  const { asPath } = useRouter();
  useEffect(() => {
    const element = ref.current;
    if (!element) return;
    if (!element.parentElement) return;
    if (typeof window === "undefined") return;
    if (!("requestAnimationFrame" in window)) return;

    const instance: Instance = {
      minFontSize: minFontSize,
      maxFontSize: maxFontSize,
      element: element,
    };

    // Request a first redraw
    requestRedraw(instance);

    const onWindowResized = () => {
      // Request redraw on window resize with a 100ms debounce.
      if (instance.timeoutId) window.clearTimeout(instance.timeoutId);
      instance.timeoutId = window.setTimeout(
        () => requestRedraw(instance),
        100
      );
    };

    window.addEventListener("resize", onWindowResized);

    return () => {
      // useEffect cleanup callback. Remove event handler, animationFrame and timeout
      window.removeEventListener("resize", onWindowResized);

      if (instance.animationFrameId) {
        window.cancelAnimationFrame(instance.animationFrameId);
      }
      if (instance.timeoutId) {
        window.clearTimeout(instance.timeoutId);
      }
    };
  }, [maxFontSize, minFontSize, ref, asPath]); // asPath in dep array to trigger re calculation on route change
};
