import { LearnMoreSectionButton } from "@/components/LearnMoreSectionButton";
import { mcn } from "@/utils/mergeClassNames";
import { CarouselImages } from "./CarouselImages";
import { CarouselTextContent } from "./CarouselTextContent";
import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { CarouselImage } from "@/_staticContent/start";

export const CarouselHeightClassNames = "h-mobile-screen laptop:h-screen";

type Props = {
  images: CarouselImage[];
  title: string;
  text: string;
  actionText: string;
  actionHref: string;
  nextSectionId: string;
};

export const CarouselSection = (props: Props) => {
  const [showCarousel, setShowCarousel] = useState(false);

  useEffect(() => {
    setShowCarousel(true);
  }, []);

  return (
    <div
      className={mcn(
        "bg-blue w-full overflow-x-hidden overflow-y-hidden",
        CarouselHeightClassNames
      )}
    >
      <Transition
        show={showCarousel}
        enter="transition opacity transform duration-[500ms]"
        enterFrom="opacity-0 translate-y-xl"
        enterTo="opacity-100 translate-y-0"
      >
        <CarouselImages images={props.images} />
        <CarouselTextContent {...props} />
        <LearnMoreSectionButton targetSectionId={props.nextSectionId} />
      </Transition>
    </div>
  );
};
