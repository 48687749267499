import { getEciuPolygonAsSrc } from "@/components/EciuPolygons/EciuPolygon";
import { getColorCode } from "@/utils/colors";
import { mcn } from "@/utils/mergeClassNames";
import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { CarouselImage } from "@/_staticContent/start";

const polygonBgStyle = {
  backgroundImage: `url(${getEciuPolygonAsSrc({
    fillColor: "transparent",
    backgroundColor: getColorCode("blue"),
  })})`,
};

const imgSizeClassNames = mcn("h-[360px] tablet-sm:h-[700px] laptop:h-screen");
const timeoutDefault = 5000;

type Props = {
  images: CarouselImage[];
};

export const CarouselImages = (props: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      const lastIndex = props.images.length - 1;
      const nextIndex = currentIndex + 1 > lastIndex ? 0 : currentIndex + 1;
      setCurrentIndex(nextIndex);
    }, timeoutDefault);
    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [currentIndex, props.images.length]);

  return (
    <div>
      <div
        className={mcn(
          "absolute z-20 w-full overflow-hidden",
          imgSizeClassNames
        )}
      >
        <div
          className={mcn(
            "scale-150 tablet-sm:scale-150 laptop:scale-125 desktop:scale-125",
            "bg-cover bg-center",
            imgSizeClassNames
          )}
          style={polygonBgStyle}
        />
      </div>

      {props.images.map((image, index) => (
        <Image
          key={index}
          src={image.src}
          quality={image.quality}
          alt={"Carousel image"}
          style={{
            objectFit: "cover",
          }}
          className={mcn(
            "absolute w-full",
            imgSizeClassNames,
            "bg-center bg-cover bg-no-repeat",
            "ease-out duration-800 transition-opacity",
            index === currentIndex ? "opacity-100" : "opacity-0",
            index === currentIndex ? "z-10" : "z-0"
          )}
          priority={index === 0}
        />
      ))}
    </div>
  );
};
