import { ScreenKey, useCurrentScreenSize } from "@/utils/useCurrentScreenSize";
import { CSSProperties } from "react";

const polygonHeightToWithRatio = 2.5;

const getPolygonHeight = (key?: ScreenKey): number => {
  switch (key) {
    case "laptop":
    case "laptop-lg":
    case "desktop":
      return 190;
  
    default:
      return 60;
  }
}

/**
 * Frames a container with eciu lines. Assumes
 * relative container.
 */
const EciuLinedPolygonFrame = () => {
  const screen = useCurrentScreenSize();
  const polygonLineHeight = getPolygonHeight(screen);
  const polygonLineStyle = { height: polygonLineHeight, width: polygonLineHeight * polygonHeightToWithRatio }
  return (
    <>
      <EciuPolygonLineUpper className="absolute top-0 -left-3" style={polygonLineStyle} />
      <EciuPolygonLineLower className="absolute bottom-0 -right-3" style={polygonLineStyle} />
    </>
  );
}

type LineArgs = {
  lineColor?: string;
  strokeWidth?: number;
  className?: string;
  style?: CSSProperties;
};

/**
 * ECIU logo line with correct angle.
 */
export const EciuPolygonLineUpper = (args: LineArgs) => {
  const lineColor = args.lineColor ?? "currentColor";
  return (
    <svg
      viewBox="2 2 250 100"
      height="100%"
      width="100%"
      className={args.className}
      style={args.style}
    >
      <line
        x1="0"
        y1="100%"
        x2="100%"
        y2="0"
        strokeWidth={args.strokeWidth ?? 4}
        stroke={lineColor}
      />
    </svg>
  );
};

/**
 * ECIU logo line with correct angle.
 */
export const EciuPolygonLineLower = (args: LineArgs) => {
  const lineColor = args.lineColor ?? "currentColor";
  return (
    <svg
      viewBox="-2 -2 250 100"
      height="100%"
      width="100%"
      className={args.className}
      style={args.style}
    >
      <line
        x1="0"
        y1="100%"
        x2="100%"
        y2="0"
        strokeWidth={args.strokeWidth ?? 4}
        stroke={lineColor}
      />
    </svg>
  );
};

export default EciuLinedPolygonFrame;