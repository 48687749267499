import { LearningOpportunityCardContainer } from "@/components/LearningOpportunityCard";
import {
  Section,
  SectionContainer,
  sectionPaddingTopClassname,
  SectionProps,
} from "@/components/Sections";
import { LearningOpportunityListItem } from "@/server/backend-api";

export type LearningOpportunityCardContainerProps = {
  heading?: string;
  learningOpps: LearningOpportunityListItem[];
  actionText?: string;
  actionHref?: string;
};

type Props = SectionProps & {
  heading?: string;
  description?: string;
  data: LearningOpportunityCardContainerProps[];
};

export function getTotalLearningOppCount(
  sections: LearningOpportunityCardContainerProps[]
) {
  let totalCount = 0;
  for (const section of sections) {
    totalCount += section.learningOpps.length;
  }
  return totalCount;
}

export const LearningOpportunitySection = ({
  data,
  heading,
  description,
  ...sectionProps
}: Props) => {
  const totalLearningOpps = getTotalLearningOppCount(data);
  if (totalLearningOpps === 0) {
    return null;
  }

  return (
    <Section className={sectionPaddingTopClassname} {...sectionProps}>
      {(heading || description) && (
        <SectionContainer defaultPadding={false} grid gridNoYGap>
          <h2 className="col-span-full text-style-headline4 tablet:text-style-headline2 mb-lg">
            {heading}
          </h2>
          <p className="col-span-full tablet:col-span-9 text-style-body1 mb-xl tablet:mb-2xl whitespace-pre-line">
            {description}
          </p>
        </SectionContainer>
      )}
      {data.map((item, index) => {
        if (item.learningOpps.length === 0) {
          return null;
        }

        return (
          <LearningOpportunityCardContainer
            key={index}
            items={item.learningOpps}
            {...item}
          />
        );
      })}
    </Section>
  );
};
