module.exports = {
  colors: {
    "light-yellow": "#FCEDC6",
    blue: "#002337",
    "blue-120": "#001724",
    "light-blue": "#305163",
    "light-green": "#D3E8E0",
    "dark-green": "#00665E",
    "green-100": "#00A984",
    "green-120": "#007254",
    "yellow-90": "#FFA80D",
    "yellow-100": "#F7A70A",
    "yellow-110": "#DB900B",
    "yellow-120": "#BF7A0C",
    "light-red": "#F4E0DF",
    "red-90": "#FF6F6C",
    "red-100": "#ED6B62",
    "red-110": "#D15B59",
    "red-120": "#B54E4E",
    white: "#FFFFFF",
    "text-primary": "#333333",
    "text-secondary": "#666666",
    "grey-10": "#F2F2F2",
    "grey-20": "#E5E5E5",
    "grey-40": "#CCCCCC",
    "grey-120": "#666666",
    "grey-160": "#333333",
    "ultra-light-blue": "#D7DCDF",
    black: "#000000",
  },
};
