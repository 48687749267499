import { mcn } from "@/utils/mergeClassNames";
import config from "../config";
import Image, { ImageLoaderProps, ImageProps } from "next/image";

type Rounded = "top-2" | "left-3" | "3" | "full";
type Props = Omit<
  ImageProps,
  "src" | "alt" | "placeholder" | "blurDataURL" | "objectFit"
> & {
  overlayClassname?: string;
  rounded?: Rounded;
  objectFit?: "object-contain" | "object-cover";
  src: ImageProps["src"] | undefined | null;
  alt?: string | undefined | null;
};

const getRoundClassname = (rounded: Rounded | undefined) => {
  switch (rounded) {
    case "top-2":
      return ["rounded-t-2", "after:rounded-t-2"];
    case "left-3":
      return ["rounded-l-3", "after:rounded-l-3"];
    case "3":
      return ["rounded-3", "after:rounded-3"];
    case "full":
      return ["rounded-full", "after:rounded-full"];
    default:
      return ["", ""];
  }
};

export const placeholder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAABaCAIAAACwpMoFAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADcSURBVHhe7dEBCQAwDMCw+5d5GLMxHyUQB3l/ljDBcYLjBMcJjhMcJzhOcJzgOMFxguMExwmOExwnOE5wnOA4wXGC4wTHCY4THCc4TnCc4DjBcYLjBMcJjhMcJzhOcJzgOMFxguMExwmOExwnOE5wnOA4wXGC4wTHCY4THCc4TnCc4DjBcYLjBMcJjhMcJzhOcJzgOMFxguMExwmOExwnOE5wnOA4wXGC4wTHCY4THCc4TnCc4DjBcYLjBMcJjhMcJzhOcJzgOMFxguMExwmOExwnOE5wnOA4wWmzB8E1xEtK2R3zAAAAAElFTkSuQmCC";

/**
 * Default image component with gradient wrapper
 */
export const EciuImage = ({
  rounded,
  objectFit,
  className,
  overlayClassname,
  src,
  layout,
  alt,
  ...rest
}: Props) => {
  const [imgRoundCN, overlayRoundClassName] = getRoundClassname(rounded);
  const realObjectFit = objectFit ?? "object-cover";
  const imgClass = `${realObjectFit} ${imgRoundCN} ${className ?? ""}`;
  const overlayClass = mcn(
    realObjectFit,
    "relative w-full h-full",
    "after:absolute after:content-[''] after:w-full after:h-full",
    "after:bg-image-gradient after:inset-0",
    `${overlayRoundClassName ?? ""} ${overlayClassname ?? ""}`
  );
  const actualLayout = layout ?? "fill";
  return (
    <div className={overlayClass}>
      <Image
        className={imgClass}
        layout={actualLayout}
        src={src ?? placeholder}
        alt={alt ?? ""}
        blurDataURL={placeholder}
        placeholder="blur"
        loader={(resolverProps: ImageLoaderProps) => {
          const { src } = resolverProps;
          if (src.startsWith("http")) {
            return src;
          }
          return `${config.cdn.url}/${src}`;
        }}
        {...rest}
      />
    </div>
  );
};
