import {
  Section,
  SectionContainer,
  SectionProps,
} from "@/components/Sections/Sections";
import Image from "next/image";
import { mcn } from "@/utils";
import Button from "@/components/Button";
import circleHeadFull from "../../public/images/illustration-circlehead-full.png";

interface Props extends SectionProps {
  hasMotivationScanResult: boolean;
  isSignedIn: boolean;
}

export function MotivationScanSection(props: Props) {
  const { hasMotivationScanResult, isSignedIn } = props;
  const circleSizes = mcn(
    "w-[285px] h-[285px]",
    "tablet-sm:w-[350px] tablet-sm:h-[350px]",
    "tablet-lg:w-[390px] tablet-lg:h-[390px]",
    "laptop:w-[470px] laptop:h-[470px]"
  );

  const circlePositioning = mcn(
    "tablet:-right-[80px]",
    "laptop:-right-[100px]",
    "desktop:left-[30px]"
  );

  const imagePositioning = mcn("-left-[30px] tablet:-left-0");

  return (
    <Section {...props} sectionPadding={false}>
      <SectionContainer
        grid
        defaultPadding={true}
        gridNoYGap={true}
        className="tablet-lg:py-2xl gap-y-6"
      >
        <div className={"col-span-full tablet:col-span-4 flex justify-center"}>
          <div className={mcn("flex justify-center relative", circleSizes)}>
            <div
              className={mcn(
                "absolute rounded-full -z-0 flex items-center",
                props.color === "light-green" && "bg-green-100/30",
                props.color === "blue" && "bg-light-blue",
                "object-fit",
                circleSizes,
                circlePositioning
              )}
            >
              <Image
                className={mcn(
                  "relative object-contain z-10 w-4/5 -4/5",
                  imagePositioning
                )}
                src={circleHeadFull}
                alt={
                  "Graphical illustration of a person with a circle for a head"
                }
              />
            </div>
          </div>
        </div>
        <div
          className={
            "col-span-full tablet:col-span-8 gap-y-md flex flex-col justify-center z-10"
          }
        >
          <h2 className={"text-style-headline2"}>
            Start your ECIU experience here!
          </h2>
          <p className={"text-style-body1"}>{getText(isSignedIn)}</p>
          <div>
            {!hasMotivationScanResult && (
              <Button
                href={"/motivation-scan/onboarding"}
                label={"Start motivation scan"}
              />
            )}
            {hasMotivationScanResult && (
              <Button
                href={"/motivation-scan/my-results"}
                label={"See results"}
              />
            )}
          </div>
        </div>
      </SectionContainer>
    </Section>
  );
}

function getText(isSignedIn: boolean): string {
  if (isSignedIn) {
    return "Not sure where to start with your learning journey? No problem! Taking this motivation scan will help you find the learning opportunities best suited to your interests and needs!";
  } else {
    return "Not sure which learning opportunity suits your interests and needs? Then take this motivation scan for some guidance before you start your learning journey. ";
  }
}
