import { mcn } from "@/utils/mergeClassNames";
import {
  LearningOpportunityStatus,
  LearningOpportunityUserStatus,
} from "@/server/backend-api/generated";
import {
  learningOppUserStatusToString,
  statusToString,
} from "@/server/backend-api/enumToText";
import { calculateDaysLeft } from "@/utils/calculateDaysLeft";

export type StatusLabelProps = {
  text: string;
  className?: string;
  includeBanner?: boolean;
  bannerSizeClassName?: string;
  color?: "green" | "yellow" | "white" | "red";
  applicationEndDate?: string | null;
  seatPercentage?: number;
};

export function getStatusLabelPropsFromStatus(
  learningOppStatus: LearningOpportunityStatus | null,
  learningOppUserStatus: LearningOpportunityUserStatus | null
): Pick<StatusLabelProps, "color" | "text"> | null {
  if (learningOppStatus === null && learningOppUserStatus === null) {
    return null;
  }

  if (learningOppUserStatus !== null) {
    const text = learningOppUserStatusToString(learningOppUserStatus);
    switch (learningOppUserStatus) {
      case LearningOpportunityUserStatus.OfferedToLearner:
      case LearningOpportunityUserStatus.LearnerApplied:
        return {
          text: text,
          color: "yellow",
        };
      case LearningOpportunityUserStatus.LearnerCompleted:
      case LearningOpportunityUserStatus.LearnerAttending:
        return {
          text: text,
          color: "green",
        };
      case LearningOpportunityUserStatus.LearnerRejectedOffer:
      case LearningOpportunityUserStatus.AdmissionOfficerDeniedApplication:
      case LearningOpportunityUserStatus.OfferToLearnerExpired:
      case LearningOpportunityUserStatus.LearnerFailed:
      case LearningOpportunityUserStatus.LearnerDroppedOut:
        return {
          text: text,
          color: "red",
        };
    }
  }
  const text = statusToString(learningOppStatus);
  switch (learningOppStatus) {
    case LearningOpportunityStatus.ComingSoon:
      return {
        text: text,
        color: "white",
      };
    case LearningOpportunityStatus.OpenForApplication:
      return {
        text: text,
        color: "green",
      };
    case LearningOpportunityStatus.ClosedForApplication:
      return {
        text: text,
        color: "red",
      };
    case LearningOpportunityStatus.Finished:
      return {
        text: text,
        color: "red",
      };
    case LearningOpportunityStatus.Unknown:
      return null;
    default:
      return null;
  }
}

export const StatusLabel = (props: StatusLabelProps) => {
  const color = props.color ?? "green";
  const bannerSize = props.bannerSizeClassName ?? "w-xs";
  return (
    <div className={mcn(props.className, "flex")}>
      {props.includeBanner === true && (
        <span
          className={mcn(
            bannerSize,
            color === "green" && "bg-green-100",
            color === "yellow" && "bg-yellow-100",
            color === "red" && "bg-red-100",
            color === "white" && "bg-light-blue"
          )}
        ></span>
      )}

      <span
        className={mcn(
          "text-style-caption text-grey-160 px-xs py-2xs rounded-r-1",
          !props.includeBanner && "rounded-l-1",
          color === "green" && "bg-light-green",
          color === "yellow" && "bg-light-yellow",
          color === "white" && "bg-white",
          color === "red" && "bg-light-red"
        )}
      >
        {props.text}
        {props.color === "green" && props.applicationEndDate && (
          <>
          <div className="font-bold">
            {calculateDaysLeft(props.applicationEndDate)} days left!
          </div>
          {props.seatPercentage !== undefined &&
            props.seatPercentage >= 70 &&
            props.seatPercentage < 100 && (
              <div className="font-bold">Only a few seats left!</div>
            )}
        </>
        )}      
      </span>
    </div>
  );
};
