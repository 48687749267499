import { useEffect, useState } from "react";
import { screens } from "../tailwind/config";

export type ScreenKey = keyof typeof screens;
/**
 * Get current tailwind screen using matchMedia (media query in js)
 */
export function useCurrentScreenSize(): ScreenKey | undefined {
  const [value, setValue] = useState<ScreenKey>();
  useEffect(() => {
    const queryList = Object.entries(screens).map(([key, value]) => ({
      key: key as ScreenKey,
      value,
      mediaQuery: window.matchMedia(`(min-width: ${value})`),
    }));

    const handler = () => {
      const [lastMatchedQuery] = queryList
        .filter((x) => x.mediaQuery.matches)
        .slice(-1);

      setValue(lastMatchedQuery?.key);
    };
    handler();
    queryList.forEach((mql) =>
      mql.mediaQuery.addEventListener("change", handler)
    );
    return () =>
      queryList.forEach((mql) =>
        mql.mediaQuery.removeEventListener("change", handler)
      );
  }, []);

  return value;
}

export function useHasDesktopHeader(): boolean {
  const currentScreenSize = useCurrentScreenSize();
  const isDesktop = ["laptop", "laptop-lg", "desktop"].includes(
    currentScreenSize ?? ""
  );
  return isDesktop;
}
