import { UIEventHandler, useEffect, useRef, useState } from "react";
import { LearningOpportunityListItem } from "../../server/backend-api";

import { CardContainerPagination } from "./CardContainerPagination";
import { LearningOpportunityCard } from "./LearningOpportunityCard";
import { useCardCountFromScreenSize } from "./useVisibleCardCountFromMediaQuery";
import { getFirstVisibleCardIndex } from "./getFirstVisibleCardIndex";
import { LearningOpportunityCardContainerShimmer } from "./LearningOpportunityCardContainerShimmer";
import { EciuPolygonIcon } from "@/components/EciuPolygons/EciuPolygon";
import { getColorCode } from "@/utils/colors";
import Button from "../Button";
import { sectionPaddingBottomClassname } from "../Sections";

const InternalItemsContainer = (props: {
  items: LearningOpportunityListItem[];
  actionText?: string;
  actionHref?: string;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [currentPosition, setCurrentPosition] = useState(0);
  const visibleCardCount = useCardCountFromScreenSize();

  useEffect(() => {
    cardRefs.current = cardRefs.current.slice(0, props.items.length);
  }, [props.items]);

  const cardCount = props.items.length;

  const onContainerScroll: UIEventHandler<HTMLDivElement> = () => {
    const firstVisibleIndex = getFirstVisibleCardIndex(containerRef, cardRefs);
    const newPosition = firstVisibleIndex
      ? Math.ceil(firstVisibleIndex / visibleCardCount)
      : 0;
    if (currentPosition !== newPosition) {
      setCurrentPosition(newPosition);
    }
  };

  return (
    <div className="overflow-hidden">
      <div
        ref={containerRef}
        className={
          "card-container flex gap-default relative " +
          "overflow-x-scroll scrollbar-none scroll-smooth " +
          "snap-x snap-mandatory "
        }
        onScroll={onContainerScroll}
      >
        {props.items.map((item, index) => (
          <div
            ref={(el) => (cardRefs.current[index] = el)}
            key={index}
            className={
              "flex-shrink-0 snap-start " +
              "card-1 tablet-sm:card-2 tablet:card-3 desktop:card-4 "
            }
          >
            <LearningOpportunityCard item={item} />
          </div>
        ))}
      </div>
      <div className={sectionPaddingBottomClassname}>
        <CardContainerPagination
          containerRef={containerRef}
          cardRefs={cardRefs}
          cardCount={cardCount}
          visibleCardCount={visibleCardCount}
          currentPosition={currentPosition}
        />
        {props.actionHref && props.actionText && (
          <div className="pt-lg container flex items-center justify-center">
            <Button href={props.actionHref}>{props.actionText}</Button>
          </div>
        )}
      </div>
    </div>
  );
};

type Props = {
  items: LearningOpportunityListItem[] | undefined;
  heading?: string;
  headingWithEciuLogo?: boolean;
  actionText?: string;
  actionHref?: string;
};
export const LearningOpportunityCardContainer = ({
  items,
  heading,
  headingWithEciuLogo = false,
  actionHref,
  actionText,
}: Props) => {
  return (
    <>
      {heading && (
        <div className="container flex items-start">
          {headingWithEciuLogo && (
            <EciuPolygonIcon
              sizeClassName="w-[32px] flex-shrink-0 mt-2xs mr-xs"
              fillColor={getColorCode("yellow")}
            />
          )}
          <h3 className="text-style-headline3">{heading}</h3>
        </div>
      )}
      {items ? (
        <InternalItemsContainer
          items={items}
          actionHref={actionHref}
          actionText={actionText}
        />
      ) : (
        <LearningOpportunityCardContainerShimmer />
      )}
    </>
  );
};
