const screens = {
  "tablet-sm": "544px",
  tablet: "776px",
  "tablet-lg": "1016px",
  laptop: "1216px",
  "laptop-lg": "1312px",
  desktop: "1600px",

  "max-mobile": { max: "543px" }, // tablet-sm - 1px
  "max-tablet-sm": { max: "775px" }, // tablet - 1px
  "max-tablet": { max: "1015px" }, // tablet-lg - 1px
  "max-tablet-lg": { max: "1215px" }, // laptop - 1px

  tall: { raw: "(min-height: 1400px)" },
  "touch-screen": {
    // "hover: none" means that the primary input device doesn't support hover which is true for touch screens.
    raw: "(hover: none)",
  },
  "no-touch-screen": {
    raw: "(hover: hover)",
  },
};

const containerPadding = {
  DEFAULT: "16px",
  "tablet-sm": "16px",
  tablet: "32px",
  "tablet-lg": "32px",
  laptop: "192px", // sidebar + 32px
  "laptop-lg": "192px", // sidebar + 32px
  desktop: "192px", // sidebar + 32px
};

const gutters = {
  DEFAULT: 12,
  "tablet-sm": 12,
  tablet: 24,
  "tablet-lg": 24,
  laptop: 32,
  "laptop-lg": 32,
  desktop: 32,
};

const textStyles = [
  {
    name: "headline1",
    fontSize: 72,
    fontSizeTablet: 50,
    fontSizeMobile: 40,
    fontWeight: "bold",
    lineHeight: 96,
    lineHeightTablet: 68,
    lineHeightMobile: 52,
  },
  {
    name: "headline2",
    fontSize: 50,
    fontSizeTablet: 40,
    fontSizeMobile: 40,
    fontWeight: 300,
    lineHeight: 68,
    lineHeightTablet: 52,
    lineHeightMobile: 44,
  },
  {
    name: "headline3",
    fontSize: 30,
    fontSizeTablet: 26,
    fontWeight: "bold",
    lineHeight: 40,
    lineHeightTablet: 36,
  },
  {
    name: "headline4",
    fontSize: 24,
    fontWeight: "normal",
    lineHeight: 32,
  },
  {
    name: "headline5",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: 28,
  },
  {
    name: "subtitle1",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 24,
  },
  {
    name: "subtitle2",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 20,
  },
  {
    name: "body1",
    fontSize: 18,
    fontSizeMobile: 16,
    fontWeight: "normal",
    lineHeight: 24,
  },
  {
    name: "body2",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: 20,
  },
  {
    name: "button",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 24,
    uppercase: true,
  },
  {
    name: "caption",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: 16,
  },
  {
    name: "overline",
    fontSize: 10,
    fontWeight: "normal",
    lineHeight: 16,
    uppercase: true,
  },
];

module.exports = {
  screens,
  containerPadding,
  gutters,
  textStyles,
};
