import { mcn } from "@/utils";
import { getColorCode } from "@/utils/colors";
import { CSSProperties, PropsWithChildren } from "react";
import EciuLinedPolygonFrame from "../EciuPolygons/EciuLinedPolygonFrame";
import { getEciuPolygonAsSrc } from "../EciuPolygons/EciuPolygon";

export type Color =
  | "white"
  | "light-yellow"
  | "blue"
  | "light-blue"
  | "light-gray"
  | "light-green"
  | "blue-polygon"
  | "yellow-polygon"
  | "black-lined-polygon-frame"
  | "light-yellow-pathway";

export type SectionProps = PropsWithChildren<{
  id?: string;
  className?: string;
  color?: Color;
  /** Min height will be screen. Default to true if isFirstSection. */
  fillScreenHeight?: boolean;
  /** Adds default padding to section (to be used in cases when padding can't be on SectionContainer) */
  sectionPadding?: boolean;
  /** Use on first section of a page. Min height will be screen and section padding will be adjusted to fit header. */
  isFirstSection?: boolean;
  /** defaults to true */
  adjustScrollMarginToStickyObjectHeaderLinks?: boolean;
}>;
type ContainerProps = PropsWithChildren<{
  className?: string;
  grid?: boolean;
  gridNoYGap?: boolean;
  defaultPadding?: boolean;
}>;

export const sectionPaddingYClassname = "py-2xl tablet-lg:py-3xl";
export const sectionPaddingBottomClassname = "pb-2xl tablet-lg:pb-3xl";
export const sectionPaddingTopClassname = "pt-2xl tablet-lg:pt-3xl";

const Section = ({
  id,
  children,
  className,
  color,
  sectionPadding,
  isFirstSection,
  fillScreenHeight,
  adjustScrollMarginToStickyObjectHeaderLinks = true,
}: SectionProps) => {
  fillScreenHeight = fillScreenHeight ?? isFirstSection;

  const style = getPossibleBackgroundImage(color);
  const hasPolygonLine = color === "black-lined-polygon-frame";
  const polygonClassNames = "bg-center bg-no-repeat bg-cover";
  const pathwayClassNames =
    "laptop:bg-learning-pathway bg-right bg-no-repeat bg-auto";
  return (
    <section
      style={style}
      className={mcn(
        className,
        "section overflow-x-hidden bg-no-repeat bg-center",
        color === "blue" && "bg-blue text-white",
        color === "light-blue" && "bg-light-blue text-white",
        color === "light-yellow" && "bg-light-yellow",
        color === "light-gray" && "bg-grey-20 text-text-primary",
        color === "white" && "bg-white text-text-primary",
        color === "light-green" && "bg-light-green text-text-primary",
        color === "blue-polygon" && `bg-blue text-white ${polygonClassNames}`,
        color === "yellow-polygon" && `bg-light-yellow ${polygonClassNames}`,
        color === "light-yellow-pathway" &&
          `bg-light-yellow ${pathwayClassNames}`,
        sectionPadding && sectionPaddingYClassname,
        isFirstSection && "laptop:before:block laptop:before:h-xl",
        fillScreenHeight && "fill-screen-height",
        "relative"
      )}
    >
      {id && (
        // Scroll margin on anchor id tag  to compensate
        // for sticky elements height
        // Header in mobile = 56px
        // Object header anchor links = 56px.
        // 111 = 56+56-1
        // 55 = 56-1
        <div
          id={id}
          className={mcn(
            "invisible",
            adjustScrollMarginToStickyObjectHeaderLinks
              ? "scroll-mt-[111px] laptop:scroll-mt-[55px]"
              : "scroll-mt-[55px] laptop:scroll-mt-0"
          )}
        />
      )}
      {hasPolygonLine && <EciuLinedPolygonFrame />}
      {children}
    </section>
  );
};

function getPossibleBackgroundImage(
  color?: Color
): Partial<CSSProperties> | undefined {
  if (!color) {
    return undefined;
  }

  if (color === "blue-polygon") {
    const polygonSrc = getEciuPolygonAsSrc({
      fillColor: getColorCode("light-blue"),
      opacity: 0.5,
    });
    return {
      backgroundImage: `url(${polygonSrc})`,
    };
  }

  if (color === "yellow-polygon") {
    const polygonSrc = getEciuPolygonAsSrc({
      fillColor: getColorCode("yellow"),
      opacity: 0.2,
    });
    return {
      backgroundImage: `url(${polygonSrc})`,
    };
  }
  return undefined;
}

const SectionContainer = ({
  children,
  className,
  grid = false,
  gridNoYGap = false,
  defaultPadding = true,
}: ContainerProps) => {
  return (
    <div
      className={mcn(
        "container",
        defaultPadding && sectionPaddingYClassname,
        grid && "grid grid-cols-12",
        grid && !gridNoYGap ? "gap-default" : "gap-x-default",
        className
      )}
    >
      {children}
    </div>
  );
};

export { Section, SectionContainer };
